<template>
    <v-app id="inspire">
        <!-- アプリケーションバー -->
        <v-app-bar app dark>
            <v-app-bar-nav-icon color="white" @click="drawer = !drawer"></v-app-bar-nav-icon>
    
            <!--タイトル-->
            <v-toolbar-title class="logo-color">Free Download Movies</v-toolbar-title>
            <v-spacer></v-spacer>
    
            <!--  カテゴリー選択  -->
            <!---<v-combobox v-if="$vuetify.breakpoint.smAndUp" v-model="selectedItem" :items="flattenCategories" label="カテゴリー" outlined dense class="search-text-design"></v-combobox>-->
            <v-select v-if="$vuetify.breakpoint.smAndUp" v-model="selectedItem" :items="flatItems" item-text="name" label="カテゴリー" outlined dense class="search-text-design" return-object ref="selectMenu" :menu-props="menuProps" @change="onCategoryChange">
                <template v-slot:item="{ item, attrs, on }">
                                                <v-list-item
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :class="{ 'v-list-item--active': item === selectedItem }"
                                                    dense
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <span v-if="item.subcategories && item.subcategories.length" class="expandable">
                                                                <v-icon
                                                                    class="expand-icon"
                                                                    @click.stop="toggleCategory(item)"
                                                                    color="white"
                                                                >
                                                                    {{ item.open ? 'mdi-minus' : 'mdi-plus' }}
                                                                </v-icon>
                                                                <span @click.stop="selectItem(item)">
                                                                    {{ item.name }}
                                                                </span>
                                                            </span>
                                                            <span v-else @click.stop="selectItem(item)" class="subcategory-indent">
                                                                {{ item.name }}
                                                            </span>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
</template>
            </v-select>

            <!--検索フォーム -->
            <v-text-field v-if="$vuetify.breakpoint.smAndUp" id="searchTextInput" dense label="検索" outlined clearable append-icon="mdi-magnify" @click:append="searchFormMovies" @keyup.enter="searchFormMovies" class="search-text-design"></v-text-field>
    
            <!-- ログイン、アップロード、ログアウト、マイページボタン -->
            <!-- <v-btn v-if="!$store.state.token" outlined @click.stop="openLoginDialog" text>
                                                                                                                                                                                                            <div class="btn-posi">
                                                                                                                                                                                                                <v-icon>mdi-login</v-icon>
                                                                                                                                                                                                                <div v-if="$vuetify.breakpoint.smAndUp">ログイン</div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        </v-btn>
                                                                                                                                                                                                        <v-btn v-if="$store.state.token" outlined @click.stop="openUploadDialog" text>
                                                                                                                                                                                                            <div class="btn-posi">
                                                                                                                                                                                                                <v-icon>mdi-upload</v-icon>
                                                                                                                                                                                                                <div v-if="$vuetify.breakpoint.smAndUp">アップロード</div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        </v-btn>
                                                                                                                                                                                                        <v-btn v-if="$store.state.token" outlined @click.stop="logout" text>
                                                                                                                                                                                                            <div class="btn-posi">
                                                                                                                                                                                                                <v-icon>mdi-logout</v-icon>
                                                                                                                                                                                                                <div v-if="$vuetify.breakpoint.smAndUp">ログアウト</div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        </v-btn>
                                                                                                                                                                                                        <v-btn v-if="$store.state.token" outlined @click.stop="openMyPageDialog" text>
                                                                                                                                                                                                            <div class="btn-posi">
                                                                                                                                                                                                                <v-icon>mdi-account</v-icon>
                                                                                                                                                                                                                <div v-if="$vuetify.breakpoint.smAndUp">マイページ</div>
                                                                                                                                                                                                            </div>
                                                                                                                                                                                                        </v-btn> -->
            <v-btn v-if="!$store.state.token" outlined @click.stop="openLoginDialog" text>
                <div class="btn-posi">
                    <v-icon>mdi-login</v-icon>
                    <div>ログイン</div>
                </div>
            </v-btn>
            <v-btn v-if="$store.state.token" outlined @click.stop="openUploadDialog" text>
                <div class="btn-posi">
                    <v-icon>mdi-upload</v-icon>
                    <div>アップロード</div>
                </div>
            </v-btn>
            <v-btn v-if="$store.state.token" outlined @click.stop="logout" text>
                <div class="btn-posi">
                    <v-icon>mdi-logout</v-icon>
                    <div>ログアウト</div>
                </div>
            </v-btn>
            <v-btn v-if="$store.state.token" outlined @click.stop="openMyPageDialog" text>
                <div class="btn-posi">
                    <v-icon>mdi-account</v-icon>
                    <div>マイページ</div>
                </div>
            </v-btn>
        </v-app-bar>

        <v-subheader v-if="$vuetify.breakpoint.smAndDown" class="sub-header-posi" dark>
            <!--  カテゴリー選択  -->
            <!-- <v-combobox v-if="$vuetify.breakpoint.smAndDown" v-model="selectedItem" :items="flattenCategories" label="カテゴリー" outlined dense class="search-text-design"></v-combobox> -->
            <v-select v-if="$vuetify.breakpoint.smAndDown" v-model="selectedItem" :items="flatItems" item-text="name" label="カテゴリー" outlined dense class="search-text-design" return-object ref="selectMenu" :menu-props="menuProps" @change="onCategoryChange">
<template v-slot:item="{ item, attrs, on }">
    <v-list-item v-bind="attrs" v-on="on" :class="{ 'v-list-item--active': item === selectedItem }" dense>
        <v-list-item-content>
            <v-list-item-title>
                <span v-if="item.subcategories && item.subcategories.length" class="expandable">
                                                                <v-icon
                                                                    class="expand-icon"
                                                                    @click.stop="toggleCategory(item)"
                                                                    color="white"
                                                                >
                                                                    {{ item.open ? 'mdi-minus' : 'mdi-plus' }}
                                                                </v-icon>
                                                                <span @click.stop="selectItem(item)">
                                                                    {{ item.name }}
                                                                </span>
                </span>
                <span v-else @click.stop="selectItem(item)" class="subcategory-indent">
                                                                {{ item.name }}
                                                            </span>
            </v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>
            </v-select>
            <!-- 検索フォーム -->
            <v-text-field v-if="$vuetify.breakpoint.smAndDown" id="searchTextInput" dense label="検索" outlined clearable append-icon="mdi-magnify" @click:append="searchFormMovies" @keyup.enter="searchFormMovies" class="search-text-design"></v-text-field>
    
        </v-subheader>
        <!-- ドラワー内容 -->
        <v-navigation-drawer v-model="drawer" fixed temporary dark>
            <v-container>
            <v-card>
                <v-card-title>カテゴリー一覧</v-card-title>
                <v-card-text>
                    <v-list dense>
                        <!-- カテゴリーとサブカテゴリーを表示 -->
<template v-for="category in categories">
    <div :key="category.value">
        <!-- カテゴリー表示部分 -->
        <v-list-item @click="searchByCategory(category)">
            <v-list-item-content>
                <v-list-item-title>
                    <!-- サブカテゴリーが存在する場合のみ「+」ボタンを表示 -->
                    <v-icon v-if="category.subcategories.length > 0" color="white" @click.stop="toggleSubcategories(category)" class="mr-2">
                        {{ category.drawerSubcategoryOpen ? 'mdi-minus' : 'mdi-plus' }}
                    </v-icon>
                    {{ category.name }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    
        <!-- サブカテゴリー表示部分 -->
        <v-list-item v-for="subcategory in category.subcategories" :key="subcategory.value" v-show="category.drawerSubcategoryOpen" @click="searchBySubcategory(subcategory); drawer = false" class="drawer-subacategory-indent">
            <v-list-item-content>
                <v-list-item-title>
                    {{ subcategory.name }}
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>
</template>
                    </v-list>
                </v-card-text>
            </v-card>
        </v-container>

</v-navigation-drawer>
        <!-- コンテンツ -->
        
        <v-main class="black sp-posi">
            <v-layout justify-center align-center>  
                <H1 v-if="$vuetify.breakpoint.smAndUp" class="web-explanation">全ての動画素材は無料で利用可能。
                    フリー動画素材を簡単にアップロード・収益化。
                    ダウンロードされるごとに収益を獲得できる動画共有プラットフォーム。</H1>
                    <H1 v-if="$vuetify.breakpoint.smAndDown" class="web-explanation">全ての動画素材は無料で利用可能。<br>
                    フリー動画素材を簡単にアップロード・収益化。<br>
                    ダウンロードされるごとに収益を獲得できる動画共有プラットフォーム。</H1>
      <v-progress-circular
        v-if="loading"
        :size="70"
        :width="7"
        color="primary"
        indeterminate
        class="loading-spinner"
      ></v-progress-circular>
    </v-layout>
            <v-container>
                <v-row>
                    <!-- 動画カード -->
                    <v-col v-for="item in displayLists" :key="item.index" cols="6" md="4" lg="4">
                        <v-card color="black">
                            <!-- <video :src="item.movie_url" controls controlsList="nodownload" style="width: 100%"></video> -->
                            <div class="video-container">
                                <video :src="item.movie_url" controls controlsList="nodownload" muted playsinline preload="metadata" :poster="item.movie_thumbnail_url"></video>
                            </div>
                            <p class="white--text text-center">{{ item.movie_title }}</p>
                            <!-- ダウンロードボタン -->
                            <v-row justify="center">
                                <v-hover>
                                    <!--<a :href="item.movie_url" :download="item.movie_title">-->
                                        <v-btn outlined class="white--text" @click="downloadVideo(item.movie_no, item.movie_title, item.movie_type)">
                                            <div class="btn-posi">
                                            <v-icon>mdi-download</v-icon>
                                            ダウンロード
                                        </div>
                                        </v-btn>
                                </v-hover>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <!-- ページネーション -->
            <v-content>
                <div class="text-center">
                    <v-pagination v-model="page" :length="length" @input="searchMovieByPage"></v-pagination>
                </div>
            </v-content>
            <!-- アップロード、ログイン、マイページダイアログ -->
            <upload-component :uploadDialog="uploadDialog" @uploadDialogResult="closeUploadDialog"></upload-component>
            <login-component :loginDialog="loginDialog" @loginDialogResult="closeLoginDialog"></login-component>
            <my-page-component :myPageDialog="myPageDialog" @myPageDialogResult="closeMyPageDialog" ref="myPageComponent"></my-page-component>
            <apc-component :apcDialog="apcDialog" @apcDialogResult="closeApcDialog"></apc-component>
            <sdc-component :sdcDialog="sdcDialog" @sdcDialogResult="closeSdcDialog"></sdc-component>
            <feedback-component :feedbackDialog="feedbackDialog" @feedbackDialogResult="closeFeedbackDialog"></feedback-component>
        </v-main>
    
        <!-- フッター -->
        <v-footer dark app>
  <v-container fluid>
    <v-row class="footer-row">
      <v-col xs="12" class="footer-text-center footer-text-up-and-down-centering">
        <span class="logo-color">FreeDownloadMovies</span>
      </v-col>
      <v-col xs="12" class="footer-text-center footer-font-size footer-text-right">
        <!-- インスタグラムのボタンを追加 -->
        <v-btn icon @click.stop="openInstagram">
          <v-icon >mdi-instagram</v-icon>
        </v-btn>
        <v-btn outlined @click.stop="openSdcDialog" text>
          <div class="footer-font-size">サイト説明</div>
        </v-btn>
        <v-btn outlined @click.stop="openApcDialog" text>
          <div class="footer-font-size">利用規約</div>
        </v-btn>
        <!---
        <v-btn outlined @click.stop="openFeedbackDialog" text>
          <div class="footer-font-size">フィードバック</div>
        </v-btn>
    -->
      </v-col>
    </v-row>
  </v-container>
</v-footer>
    </v-app>
</template>
  
<script>
import UploadComponent from "../components/UploadComponent.vue";
import MyPageComponent from "../components/MyPage.vue";
import LoginComponent from "../components/LoginComponet.vue";
import AcceptableUsePolicComponent from "../components/AcceptableUsePolicyComponet.vue";
import SiteDescriptionComponet from "../components/SiteDescriptionComponet.vue";
import FeedbackComponet from "../components/FeedbackComponet.vue";
export default {
    name: "MainPage",

    components: {
        "upload-component": UploadComponent,
        "login-component": LoginComponent,
        "my-page-component": MyPageComponent,
        "apc-component": AcceptableUsePolicComponent,
        "sdc-component": SiteDescriptionComponet,
        "feedback-component": FeedbackComponet,
    },

    data() {
        return {

            // ドロワー表示用
            drawer: null,
            allCategories: { name: '全てのカテゴリー' },
            // ページネーション用データ
            page: 1,
            length: 1,
            itemsPerPage: 15,

            // ダイアログ管理用データ
            uploadDialog: false,
            loginDialog: false,
            myPageDialog: false,
            apcDialog: false,
            sdcDialog: false,
            feedbackDialog: false,

            //カテゴリー選択時値
            selectedItem: { name: '全てのカテゴリー', value: '0', open: false, subcategories: [] },

            // 映画データと検索用データ
            movieData: [],
            displayLists: [],
            searchText: '',

            // サムネ用URL
            thumbnailUrl: null,

            loading: false, // ローディング状態を管理するプロパティ

            categories: [
                { name: '全てのカテゴリー', value: '0', open: false, drawerSubcategoryOpen: false, subcategories: [] },
                { name: '自然と風景', value: '1', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: '山と谷', value: '1-1' }, { name: '森と林', value: '1-2' }, { name: '海と海岸', value: '1-3' }, { name: '湖と川', value: '1-4' }] },
                { name: '都市と建築物', value: '2', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: '街並みと景観', value: '2-5' }, { name: '建物と構造物', value: '2-6' }, { name: '道路と交通', value: '2-7' }, { name: '公園と庭園', value: '2-8' }] },
                { name: '人々と生活', value: '3', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: '日常生活', value: '3-9' }, { name: '仕事と職場', value: '3-10' }, { name: '学校と教育', value: '3-11' }, { name: '祭りとイベント', value: '3-12' }] },
                { name: 'アニメーションとエフェクト', value: '4', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: 'キャラクターとストーリー', value: '4-13' }, { name: '特殊効果とCG', value: '4-14' }, { name: 'モーショングラフィックス', value: '4-15' }, { name: 'ビジュアルエフェクト', value: '4-16' }] },
                { name: 'テクノロジーとサイエンス', value: '5', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: 'コンピューターとデバイス', value: '5-17' }, { name: 'ロボットと人工知能', value: '5-18' }, { name: '宇宙と天文学', value: '5-19' }, { name: '医学と健康', value: '5-20' }] },
                { name: 'アートとクリエイティブ', value: '6', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: '絵画と彫刻', value: '6-21' }, { name: '写真とイラスト', value: '6-22' }, { name: 'デザインとグラフィックス', value: '6-23' }, { name: 'パフォーマンスアート', value: '6-24' }] },
                { name: 'スポーツとアクティビティ', value: '7', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: 'チームスポーツ', value: '7-25' }, { name: 'エクストリームスポーツ', value: '7-26' }, { name: 'レジャーとレクリエーション', value: '7-27' }, { name: 'アウトドアアクティビティ', value: '7-28' }] },
                { name: 'フードと飲料', value: '8', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: '料理とレシピ', value: '8-29' }, { name: 'レストランとカフェ', value: '8-30' }, { name: '食材と飲み物', value: '8-31' }, { name: 'お菓子とスイーツ', value: '8-32' }] },
                { name: '恋愛とロマンス', value: '9', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: 'デートスポット', value: '9-33' }, { name: 'カップルの瞬間', value: '9-34' }, { name: 'プロポーズ', value: '9-35' }, { name: '結婚式', value: '9-36' }] },
                { name: '動物とペット', value: '10', open: false, drawerSubcategoryOpen: false, subcategories: [{ name: '犬と猫', value: '10-37' }, { name: '野生動物', value: '10-38' }, { name: '水生生物', value: '10-39' }, { name: '鳥と爬虫類', value: '10-40' }] },
                { name: 'その他', value: '11', open: false, drawerSubcategoryOpen: false, subcategories: [] },
            ],

            flattenCategories: [],
            selectedCategory: 0,
            categoryOnlySearchFlg: false,
        };
    },
    computed: {
        flatItems() {
            const flatList = [];
            const seen = new Set(); // 重複チェック用セット
            this.categories.forEach((category) => {
                if (!seen.has(category.value)) {
                    seen.add(category.value);
                    flatList.push(category); // カテゴリーを追加
                }
                // サブカテゴリーが開かれている場合にのみ追加
                if (category.open && category.subcategories && category.subcategories.length) {
                    category.subcategories.forEach((subcategory) => {
                        if (!seen.has(subcategory.value)) {
                            seen.add(subcategory.value);
                            flatList.push(subcategory);
                        }
                    });
                }
            });
            return flatList;
        },
    },

    created() {
        this.flattenCategories = this.flattenCategoryList();
    },

    mounted() {
        // ページがロードされたら映画を検索する
        this.searchFormMovies();

    },

    methods: {
        openInstagram() {
            window.open('https://www.instagram.com/ta.01/', '_blank');
        },
        flattenCategoryList() {
            const flattenList = [];
            this.categories.forEach(category => {
                flattenList.push({ text: category.name, value: category.value });
                category.subcategories.forEach(subcategory => {
                    flattenList.push({ text: subcategory.name, value: subcategory.value });
                });
            });
            return flattenList;
        },
        // ログインダイアログを表示
        openLoginDialog() {
            this.loginDialog = true;
        },
        // アップロードダイアログを表示
        openUploadDialog() {
            this.uploadDialog = true;
        },
        // マイページダイアログを表示
        openMyPageDialog() {
            this.myPageDialog = true;
            // ユーザー情報取得とアップロード映画取得のメソッドを呼び出す
            this.$refs.myPageComponent.userInfoAcq();
            this.$refs.myPageComponent.getUpMovies();
        },
        openApcDialog() {
            this.apcDialog = true;
        },
        openSdcDialog() {
            this.sdcDialog = true;
        },
        openFeedbackDialog() {
            this.feedbackDialog = true;
        },
        // ダイアログを閉じる
        closeUploadDialog() {
            this.uploadDialog = false;
        },
        closeLoginDialog() {
            this.loginDialog = false;
        },
        closeMyPageDialog() {
            this.myPageDialog = false;
        },
        closeApcDialog() {
            this.apcDialog = false;
        },
        closeSdcDialog() {
            this.sdcDialog = false;
        },
        closeFeedbackDialog() {
            this.feedbackDialog = false;
        },
        // ログアウト
        logout() {
            this.$store.commit("setToken", null);
            alert("ログアウトが完了しました。");
        },
        //検索フォーム入力検索
        searchFormMovies() {
            this.categoryOnlySearchFlg = false;
            this.searchMovie();
        },
        // 映画検索
        searchMovie() {
            // 検索テキスト取得
            let formData = new FormData();

            this.searchText = document.getElementById("searchTextInput").value;
            if (this.searchText == null) {
                this.searchText = '';
            }

            // カテゴリー限定サーチフラグがtrueだったらカトゴリーだけで検索をする
            if (this.categoryOnlySearchFlg == true) {
                this.searchText = '';
                formData.append("selectedCategory", this.selectedCategory)

            } else { //カテゴリー限定サーチフラグがfalseならセレクトのカテゴリー検索を使う
                formData.append("selectedCategory", this.selectedItem.value)
            }

            formData.append("searchText", this.searchText);

            formData.append("limit", this.itemsPerPage);
            formData.append("offset", (this.page - 1) * this.itemsPerPage);
            this.loading = true; // 非同期処理の開始前にローディングを表示

            // 映画検索
            this.axios.post("/api/movieSearch", formData)
                .then((response) => {

                    console.log(response.data);
                    this.movieData = response.data.movies;
                    this.length = Math.ceil(response.data.totalItems / this.itemsPerPage);
                    this.displayLists = [];
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false; // 非同期処理の完了後にローディングを非表示
                    this.displayLists = this.movieData
                    this.displayLists.splice();
                    this.movieData.splice();
                });
        },
        // ページ切り替え時の映画検索
        searchMovieByPage() {
            this.loading = true; // 非同期処理の開始前にローディングを表示
            let formData = new FormData();
            // カテゴリー限定サーチフラグがtrueだったらカトゴリーだけで検索をする
            if (this.categoryOnlySearchFlg == true) {
                this.searchText = '';
                formData.append("selectedCategory", this.selectedCategory)
                //カテゴリー限定サーチフラグがfalseならセレクトのカテゴリー検索を使う
            } else {
                formData.append("selectedCategory", this.selectedItem.value)
            }
            formData.append("searchText", this.searchText);
            formData.append("limit", this.itemsPerPage);
            formData.append("offset", (this.page - 1) * this.itemsPerPage);

            // 映画検索
            this.axios.post("/api/movieSearch", formData)
                .then((response) => {
                    console.log(response.data);
                    this.movieData = response.data.movies;
                    this.length = Math.ceil(response.data.totalItems / this.itemsPerPage);
                    this.displayLists = [];
                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => {
                    this.loading = false; // 非同期処理の完了後にローディングを非表示
                    this.displayLists = this.movieData
                    this.displayLists.splice();
                    this.movieData.splice();
                });
        },
        downloadVideo(movieNo, movieTitle, movieType) {
            if (!movieNo) {
                alert('Please enter a file key');
                return;
            }
            this.axios.get(`/api/download`, {
                params: { key: movieNo },
                responseType: 'arraybuffer', // バイナリデータとしてレスポンスを受け取る
            }).then((response) => {
                console.log("レスポンスデータ" + response)
                console.log(response)
                console.log(movieType)
                // レスポンスデータからBlobオブジェクトを作成
                const blob = new Blob([response.data], { type: movieType });
                const url = window.URL.createObjectURL(blob);
                console.log(url)
                // ダウンロードリンクを作成してクリックイベントを発火
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', movieTitle); // ファイル名をキー名に設定
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch((error) => {
                console.error('File download failed:', error);
                alert('File download failed');
            })
        },
        toggleCategory(category) {
            category.open = !category.open;
        },
        selectItem(item) {
            // アイテムを選択してv-selectに反映
            this.selectedItem = item;
            // メニューを閉じる
            this.$refs.selectMenu.blur();
        },
        onCategoryChange(selected) {
            // カテゴリーまたはサブカテゴリーが選択されたときの処理
            console.log('Selected:', selected);
        },
        //ドロワーのカテゴリー一覧挙動
        toggleSubcategories(category) {
            // サブカテゴリーの表示/非表示を切り替える
            category.drawerSubcategoryOpen = !category.drawerSubcategoryOpen;
        },
        searchByCategory(category) {
            // カテゴリーに基づいて検索を実行するロジック
            this.selectedCategory = category.value
            this.categoryOnlySearchFlg = true;
            console.log(`カテゴリー「${category.name}」で検索します。${category.value}`);
            this.searchMovie();
            this.drawer = false
        },
        searchBySubcategory(subcategory) {
            // サブカテゴリーに基づいて検索を実行するロジック
            this.selectedCategory = subcategory.value
            this.categoryOnlySearchFlg = true;
            console.log(`サブカテゴリー「${subcategory.name}」で検索します。`);
            this.searchMovie();
            this.drawer = false
        },

    },
}
</script>

<style scoped>
/* スマホ */

/* @mixin display_sp {
    @media (max-width: $sp-max) {
        .serch-tex-design {
            margin-top: 2%;
        }
    }
}

@mixin display_pc {
    .serch-tex-design {
        margin-top: 6.5%;
    }
} */

@media (min-width: 961px) {
    .search-text-design {
        height: 38px;
    }
    .footer-text-up-and-down-centering {
        display: flex;
        align-items: center
    }
    .footer-text-right {
        text-align: right;
    }
    .web-explanation {
        width: 95%;
        color: #fff;
        font-size: 15px;
        text-align: center;
        margin-bottom: 0px;
    }
}

/* スマホ表示時に検索フォームを表示 */

@media (max-width: 960px) and (min-width: 481px) {
    .search-text-design {
        height: 38px;
    }
    .footer-text-up-and-down-centering {
        display: flex;
        align-items: center
    }
    .footer-text-right {
        text-align: right;
    }
    .v-footer {
        padding: 0;
    }
}

@media (max-width: 480px) {
    .search-text-design {
        height: 38px;
        width: 50%;
    }
    .sub-header-posi {
        margin-top: 56px !important;
        background-color: #272727 !important;
        position: fixed !important;
        top: 0px;
        z-index: 1;
    }
    .sp-posi {
        margin-top: 60px;
    }
    .footer-row {
        display: block !important;
        ;
    }
    .footer-font-size {
        font-size: x-small !important;
    }
    .footer-text-center {
        text-align: center;
        padding: 2px !important;
    }
    .web-explanation {
        width: 95%;
        color: #fff;
        font-size: 10px;
        text-align: center;
        margin-bottom: 0px;
    }
}



.btn-posi {
    display: flex !important;
    flex-flow: column !important;
    align-items: center !important;
    font-size: 8px;
}

.logo-color {
    color: #ACB6E5;
    background: -webkit-linear-gradient(0deg, #7fffd4, #00bfff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 5px #7fffd4, 0 0 5px #00bfff
}

.sub-title-color {
    color: #ACB6E5;
    background: -webkit-linear-gradient(0deg, #ff69b4, #ff00ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 5px #ff69b4, 0 0 5px #ff00ff
}

/* .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
} */

.loading-spinner {
  position: fixed; /* 画面に固定 */
  top: 50%; /* 上下中央に配置 */
  left: 50%; /* 左右中央に配置 */
  transform: translate(-50%, -50%); /* 正確な中央に調整 */
  z-index: 100; /* 他の要素より前面に表示 */
}

.video-container {
    position: relative;
    width: 100%;
    /* コンテナの幅を親要素に合わせる */
    padding-top: 56.25%;
    /* 16:9のアスペクト比 */
    overflow: hidden;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.theme--light.v-list {
    background: #1e1e1e;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(255, 255, 255, 0.7);
}

.v-list-item--active {
    background-color: #424242 !important;
}

.v-list-item--dense {
    padding-left: 0px;
}

.expandable .v-list-item__icon {
    margin-right: 8px;
}

.subcategory-indent {
    padding-left: 20px;
}

/* 必要に応じてスタイルを調整 */

.drawer-subacategory-indent {
    padding-left: 65px !important
}


</style>